.cropper input[type='range' i]::-moz-range-thumb {
  background-color: #00c5a3;
  color: #00c5a3;
}

.cropper input[type='range' i]::-webkit-slider-thumb {
  background-color: #00c5a3;
  color: #00c5a3;
}

.cropper input[type='range' i]::-ms-thumb {
  background-color: #00c5a3;
  color: #00c5a3;
}

.cropper input[type='range' i]::-webkit-slider-runnable-track {
  background-color: #e8e8e8;
  color: #e8e8e8;
}

.cropper input[type='range' i]::-moz-range-track {
  background-color: #e8e8e8;
  color: #e8e8e8;
}

.cropper .cr-boundary {
  border-radius: 14px;
}

.cropper .cr-viewport {
  border-radius: 14px;
}
